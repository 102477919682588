<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<template v-if="!loading && hasData">
			<b-row>
				<b-col
					class="d-flex"
					:class="['xs', 'sm'].includes(windowWidth) ? 'col-12' : 'col-5'"
					style="gap:10px;">
					<b-button
						class="float-right mb-4"
						variant="primary"
						@click="!redirect ? $router.go(-1) : $router.push({ name: 'Orders' })">
						<span class="fa fa-arrow-left" />
					</b-button>
					<h5 :class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : 'mt-2'">
						<b style="margin-right: 10px;">{{ `${translate('invoice_no')} ${data.attributes.order_info.invoice_no}` }}</b>
						<span
							v-if="data.attributes.is_fraud"
							class="badge badge-danger mr-2">{{ translate('order_is_fraud') }}</span>
						<span
							v-if="data.attributes.is_invisible"
							class="badge badge-secondary">{{ translate('order_is_invisible') }}</span>
					</h5>
				</b-col>
				<b-col class="col-7 d-flex justify-content-end">
					<template
						v-if="isAdmin
							&& showApprovalButtons
							&& $can('orders','verify')
							&& !['xs', 'sm'].includes(windowWidth)">
						<b-button
							variant="success"
							class="bg-success-alt mb-4 mr-2"
							@click="approveOrder">
							<i class="fas fa-check mr-1" />
							<span>{{ translate('approve') }}</span>
						</b-button>
						<b-button
							variant="danger"
							class="mb-4 mr-2"
							@click="rejectOrder">
							<i class="fas fa-times mr-1" />
							<span>{{ translate('reject') }}</span>
						</b-button>
					</template>
					<b-button
						v-if="isAdmin
							&& $can('orders','flag')
							&& showMarkAsFraudButton
							&& !['xs', 'sm'].includes(windowWidth)"
						class="mb-4 mr-2"
						variant="danger"
						@click="fraudOrder">
						<span>{{ translate('mark_as_fraud') }}</span>
					</b-button>
					<b-button
						v-if="isAdmin && $can('orders','complete') && ['processing', 'pick_up'].includes(data.attributes.order_info.status) && !['xs', 'sm'].includes(windowWidth)"
						class="bg-primary-alt mb-4 mr-2"
						@click="completeOrder">
						<span>{{ translate('mark_as_complete') }}</span>
					</b-button>
					<router-link
						v-if="!isAdmin && data.attributes.can_payment_method_change"
						:to="{ name: isPickupOrder ? 'PSChangePaymentMethodMain' : 'ChangePaymentMethodMain', params: { order_id: data.id } }"
						class="d-none d-md-block">
						<b-button
							class="float-right mb-4 mr-1"
							variant="primary">
							{{ translate('change_payment_method') }}
						</b-button>
					</router-link>
					<router-link
						v-if="data.attributes.wallet_address"
						:to="{ name: 'CryptoPaymentsDetails', params: { wallet_address: data.attributes.wallet_address } }"
						class="d-none d-md-block"
						target="_blank">
						<b-button
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'mb-4 mr-1'"
							class="float-right mb-4 mr-1"
							variant="primary">
							<i
								class="fab fa-bitcoin"
								aria-hidden="true" />
							{{ translate('crypto_payment_details') }}
						</b-button>
					</router-link>
				</b-col>
			</b-row>
			<b-row class="d-flex d-md-none">
				<template
					v-if="isAdmin
						&& $can('orders','verify')
						&& showApprovalButtons">
					<b-col
						class="col-12 mb-2">
						<b-button
							class="bg-success-alt btn-block"
							variant="success"
							@click="approveOrder">
							<i class="fas fa-check mr-1" />
							<span>{{ translate('approve') }}</span>
						</b-button>
					</b-col>
					<b-col class="col-12 mb-2">
						<b-button
							class="btn-block"
							variant="danger"
							@click="rejectOrder">
							<i class="fas fa-times mr-1" />
							<span>{{ translate('reject') }}</span>
						</b-button>
					</b-col>
				</template>
				<b-col
					v-if="isAdmin
						&& $can('orders','flag')
						&& showMarkAsFraudButton"
					class="col-12 mb-2">
					<b-button
						class="btn-block"
						variant="danger"
						@click="fraudOrder">
						<span>{{ translate('mark_as_fraud') }}</span>
					</b-button>
				</b-col>
				<b-col
					v-if="isAdmin
						&& $can('orders','complete')
						&& ['processing', 'pick_up'].includes(data.attributes.order_info.status)"
					class="col-12 mb-2">
					<b-button
						class="bg-primary-alt btn-block"
						@click="completeOrder">
						<span>{{ translate('mark_as_complete') }}</span>
					</b-button>
				</b-col>
				<b-col class="col-12">
					<router-link
						v-if="!isAdmin && data.attributes.can_payment_method_change"
						:to="{ name: isPickupOrder ? 'PSChangePaymentMethodMain' : 'ChangePaymentMethodMain', params: { order_id: data.id } }">
						<b-button
							class="w-100 mb-2"
							variant="primary">
							{{ translate('change_payment_method') }}
						</b-button>
					</router-link>
				</b-col>
				<b-col class="col-12">
					<router-link
						v-if="data.attributes.wallet_address"
						:to="{ name: 'CryptoPaymentsDetails', params: { wallet_address: data.attributes.wallet_address } }"
						target="_blank">
						<b-button
							class="w-100"
							variant="primary">
							<i
								class="fab fa-bitcoin"
								aria-hidden="true" />
							{{ translate('crypto_payment_details') }}
						</b-button>
					</router-link>
				</b-col>
				<b-col class="col-12">
					<hr>
				</b-col>
			</b-row>
			<b-card class="mb-3">
				<b-row>
					<b-col class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('order_date') }}</b>: {{ $moment(data.attributes.order_info.date_without_format.date).format(dateFormat) }}
						</p>
					</b-col>
					<b-col class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('order_paid_date') }}</b>: {{ data.attributes.order_info.paid_at ? $moment(data.attributes.order_info.paid_at.date).format(dateFormat) : '-' }}
						</p>
					</b-col>
					<b-col class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('order_id') }}</b>: {{ data.attributes.order_info.order_id }}
						</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="col-12 col-md-4">
						<p class="mb-0">
							<b>{{ translate('payment') }}</b>: <span v-if="data.attributes.order_info.wallet_split">{{ translate('cashondelivery') }} + </span> {{ data.attributes.order_info.payment_method }}
						</p>
					</b-col>
					<b-col class="col-12 col-md-4">
						<template v-if="data.attributes.user_info">
							<p class="mb-0">
								<b>{{ translate('user') }}</b>: {{ data.attributes.user_info }}
							</p>
						</template>
						<template v-else>
							<template v-if="data.attributes.order_info.shipping_method_code_name">
								<p class="mb-0">
									<b>{{ translate('shipping') }}</b>: {{ shippingMethod }}
								</p>
							</template>
						</template>
					</b-col>
					<b-col class="col-12 col-md-4">
						<template v-if="data.attributes.order_info.shipping_method_code_name && data.attributes.user_info">
							<p class="mb-0">
								<b>{{ translate('shipping') }}</b>: {{ shippingMethod }}
							</p>
						</template>
						<template v-else>
							<template v-if="data.attributes.tracking.length != 0">
								<b>{{ translate('tracking_number') }}</b>:
								<template
									v-if="shippingMethodsLinkable.includes(data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['carrier_code'])">
									<a
										:href="shippingMethodsLinks[data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['carrier_code']](data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_number'])"
										target="_blank">
										{{ data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_number'] }}
									</a>
								</template>
								<template v-else>
									{{ data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_number'] }}
								</template>
							</template>
						</template>
					</b-col>
					<b-col class="col-12 col-md-4">
						<template v-if="(data.attributes.tracking.length != 0) && data.attributes.order_info.shipping_method_code_name && data.attributes.user_info">
							<b>{{ translate('tracking_number') }}</b>:
							<template
								v-if="shippingMethodsLinkable.includes(data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['carrier_code'])">
								<a
									:href="shippingMethodsLinks[data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['carrier_code']](data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_number'])"
									target="_blank">
									{{ data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_number'] }}
								</a>
							</template>
							<template v-else>
								{{ data.attributes.tracking[Object.keys(data.attributes.tracking)[0]]['tracking_number'] }}
							</template>
						</template>
					</b-col>
				</b-row>
				<hr>
				<b-row>
					<b-col class="col-12 col-md-4">
						<b>{{ translate('order_status') }}</b>: {{ translate(data.attributes.order_info.status) }}
					</b-col>
					<b-col class="col-12 col-md-4">
						<b>{{ translate('order_type') }}</b>: {{ translate(data.attributes.order_type.replace('-', '_')) }}
					</b-col>
					<b-col class="col-12 col-md-4">
						<b>{{ translate('total_bvs') }}</b>: {{ data.attributes.total_bvs }}
					</b-col>
				</b-row>
				<b-row>
					<b-col
						v-if="data.attributes.tracking_status !== ''"
						class="col-12 col-md-4">
						<b>{{ translate('tracking_status') }}</b>: {{ translate(data.attributes.tracking_status) }}
					</b-col>
				</b-row>
				<template
					v-if="isAdmin && data.attributes.sponsor_info.type === distributor">
					<hr>
					<b-row>
						<b-col class="col-12 col-md-4">
							<b>{{ translate('sponsor') }}</b>: <span
								class="btn-link exchange-text pointer"
								@click="toDashboard({ distributorId: data.attributes.sponsor_info.id.toString() })">
								{{ data.attributes.sponsor_info.id }} - {{ data.attributes.sponsor_info.username }}
							</span> {{ data.attributes.sponsor_info.name }}
						</b-col>
					</b-row>
				</template>
			</b-card>
			<b-row v-if="(Object.keys(data.attributes.billing).length > 0 && !data.attributes.hide_billing) || Object.keys(data.attributes.shipping).length > 0">
				<b-col>
					<div class="table-responsive">
						<table class="table table-bordered mb-0 table-hover table-striped">
							<thead>
								<tr>
									<th
										v-if="Object.keys(data.attributes.shipping).length > 0 && !isPickupOrder">
										{{ translate('shipping_address') }}
									</th>
									<th
										v-if="Object.keys(data.attributes.billing).length > 0">
										{{ translate('billing_address') }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr style="vertical-align: top">
									<td
										v-if="Object.keys(data.attributes.shipping).length > 0 && !isPickupOrder"
										style="width: 50%">
										<p class="mb-1">
											<b>{{ data.attributes.shipping.name }}</b>
										</p>
										<p
											v-if="data.attributes.id_number"
											class="mb-1">
											{{ data.attributes.id_number }}
										</p>
										<p class="mb-1 text-wrap">
											{{ data.attributes.shipping.line1 }}
										</p>
										<p class="mb-1 text-wrap">
											{{ data.attributes.shipping.line2 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.shipping.line3 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.shipping.line4 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.shipping.line5 }}
										</p>
										<template v-if="data.attributes.id_number && !isAdmin">
											<b-row>
												<b-col
													sm="12"
													md="9">
													<p>{{ translate('document_status') }} : <strong>{{ translate(`verification_status_${data.attributes.verification_status}`) }}</strong></p>
												</b-col>
												<b-col
													sm="12"
													md="3">
													<b-button
														variant="primary"
														size="sm"
														class="btn-block"
														@click="showDocumentModal = true">
														{{ translate(data.attributes.verification_status === 'valid' ? 'view' : 'update') }}
													</b-button>
												</b-col>
											</b-row>
										</template>
									</td>
									<td
										v-if="Object.keys(data.attributes.billing).length > 0"
										style="width: 50%">
										<p class="mb-1">
											<b>{{ data.attributes.billing.name }}</b>
										</p>
										<p class="mb-1 text-wrap">
											{{ data.attributes.billing.line1 }}
										</p>
										<p class="mb-1 text-wrap">
											{{ data.attributes.billing.line2 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.billing.line3 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.billing.line4 }}
										</p>
										<p class="mb-1">
											{{ data.attributes.billing.line5 }}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div class="table-responsive">
						<table class="table mb-0 table-bordered table-hover table-striped">
							<thead>
								<tr>
									<th>
										{{ translate('description') }}
									</th>
									<th class="text-center">
										{{ translate('quantity') }}
									</th>
									<th class="text-center">
										{{ translate('unit_price') }}
									</th>
									<th class="text-center">
										{{ translate('total_price') }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in data.attributes.products"
									:key="`${index}_products`">
									<td class="align-middle">
										<p class="mb-0">
											{{ item.item }}
										</p>
									</td>
									<td class="align-middle text-center">
										<p class="mb-0">
											{{ item.qty }}
										</p>
									</td>
									<td class="align-middle text-right">
										<p class="mb-0">
											{{ item.price }}
										</p>
									</td>
									<td class="align-middle text-right">
										<p class="mb-0">
											{{ item.sub_total }}
										</p>
									</td>
								</tr>
								<tr
									v-for="(item, index) in data.attributes.totals"
									:key="`${index}_totals`">
									<td
										colspan="3"
										class="text-right">
										{{ item.code_name == 'shipping' ? translate('shipping_and_handling') : translate(item.code_name) }}
									</td>
									<td class="text-right">
										{{ item.format_value }}
									</td>
								</tr>
								<tr>
									<td
										colspan="3"
										class="text-right">
										<b>{{ translate('total') }}</b>
									</td>
									<td class="text-button text-right">
										<b>{{ data.attributes.order_info.total }}</b>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</b-col>
			</b-row>
			<div v-if="isParentMultiPayment">
				<b-alert
					show
					variant="info"
					v-html="translate('admin_fee_does_not_included', { fee: adminFee })" />
			</div>
			<div v-if="data.attributes.promotions.length > 0">
				<h4 class="">
					{{ translate('zlem_promo_title') }}
				</h4>
				<p>{{ data.attributes.promotions[0] }}</p>
			</div>
			<update-order-document-modal
				v-if="!isAdmin && data.attributes.id_number && showDocumentModal"
				:order-id="data.id"
				:id-number="data.attributes.id_number"
				:ship-to="data.attributes.shipping.name"
				:country-code="data.attributes.country_code"
				:verification-status="data.attributes.verification_status"
				@close="showDocumentModal = false" />
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import DashboardRedirect from '@/mixins/DashboardRedirect';
import OrderVerification from '@/mixins/OrderVerification';
import WindowSizes from '@/mixins/WindowSizes';
import Order from '@/util/Order';
import {
	OrdersDetails, Products, PurchasePayment, PurchaseShipping, Cart, TrackingStatus, Grids,
} from '@/translations';
import { YMD_FORMAT } from '@/settings/Dates';
import { ORDER_STATUSES as statuses, ORDER_STATUSES } from '@/settings/Statuses';
import { SHIPPING_METHOD_CODES, SHIPPING_METHODS_LINKS, SHIPPING_METHODS_LINKABLE } from '@/settings/Shipping';
import { admin, distributor } from '@/settings/Roles';
import UpdateOrderDocumentModal from './UpdateOrderDocumentModal';

export default {
	name: 'OrdersDetailsTemplate',
	messages: [OrdersDetails, Products, PurchasePayment, PurchaseShipping, Cart, TrackingStatus, Grids],
	components: { UpdateOrderDocumentModal },
	mixins: [DashboardRedirect, OrderVerification, WindowSizes],
	props: {
		data: {
			type: Object,
			required: true,
		},
		loading: {
			type: Boolean,
			required: true,
		},
		hasData: {
			type: Boolean,
			required: true,
		},
		redirect: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			dateFormat: YMD_FORMAT,
			shippingMethodsLinkable: SHIPPING_METHODS_LINKABLE,
			shippingMethodsLinks: SHIPPING_METHODS_LINKS,
			admin,
			distributor,
			statuses: ORDER_STATUSES,
			orderComplete: new Order(),
			orderFraud: new Order(),
			orderVerify: new Order(),
			alert: new this.$Alert(),
			showDocumentModal: false,
		};
	},
	computed: {
		isAdmin() {
			return this.admin.includes(this.$user.details().type);
		},
		shippingCarrier() {
			try {
				const { shipping_method_code_name: shippingMethod } = this.data.attributes.order_info;
				return SHIPPING_METHOD_CODES[shippingMethod].carrier;
			} catch (error) {
				return null;
			}
		},
		showMarkAsFraudButton() {
			try {
				const { status } = this.data.attributes.order_info;
				return status !== statuses.pending && !this.data.attributes.is_fraud;
			} catch (error) {
				return false;
			}
		},
		showApprovalButtons() {
			try {
				const { status } = this.data.attributes.order_info;
				return status === ORDER_STATUSES.paymentReview;
			} catch (error) {
				return false;
			}
		},
		isPickupOrder() {
			return this.data.attributes.is_pickup_order;
		},
		shippingMethod() {
			if (this.isPickupOrder) {
				return this.translate('pickup_title');
			}

			return (this.shippingCarrier ? `${this.shippingCarrier.toUpperCase()} - ` : '') + this.translate(this.data.attributes.order_info.shipping_method_code_name);
		},
		isParentMultiPayment() {
			try {
				const { is_multi_payment: isMultiPayment, is_parent_order: isParentOrder } = this.data.attributes.multi_payment_info;
				return isMultiPayment && isParentOrder;
			} catch (error) {
				return false;
			}
		},
		adminFee() {
			try {
				return this.data.attributes.multi_payment_info.admin_fee;
			} catch (error) {
				return 0;
			}
		},
	},
	methods: {
		completeOrder() {
			const trans = {
				title: this.translate('mark_as_complete'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					password: password.value,
				};
				this.orderComplete.complete(this.data.id, payload).then(() => {
					this.alert.toast('success', this.translate('order_updated'));
					this.$emit('updated');
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.orderComplete.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.orderComplete.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		fraudOrder() {
			const trans = {
				title: this.translate('mark_as_fraud_alert_title'),
				password: this.translate('password'),
				notesTitle: this.translate('notes'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
				message: this.translate('mark_as_fraud_alert_message'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.password, trans.notesTitle, options).then((response) => {
				const { value } = response;
				const payload = {
					notes: value.notes,
					password: value.password,
				};
				this.orderFraud.fraud(this.data.id, payload).then(() => {
					this.alert.toast('success', this.translate('order_updated'));
					this.data.attributes.is_fraud = !this.data.attributes.is_fraud;
					this.$emit('updated');
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.orderFraud.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.orderFraud.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		approveOrder() {
			this.approveOrderVerification(this.data.id, { onSuccess: () => { this.$emit('updated'); } });
		},
		rejectOrder() {
			this.rejectOrderVerification(this.data.id, { onSuccess: () => { this.$emit('updated'); } });
		},
	},
};
</script>
